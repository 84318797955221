<template>
  <div class="slider-container">
    <div
      class="image-container"
      v-for="(object, idx) in project.slider"
      :key="idx"
    >
      <img
        :src="object.image"
        class="slider-image"
        alt="project-image"
        loading="lazy"
      />
      <p class="credit" v-show="object.credit">@{{ object.credit }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['project'],
};
</script>

<style lang="scss" scoped>
.slider-container {
  width: 100%;
  height: 85vh;
  margin-bottom: 8.3rem;
  overflow-y: scroll;
  scrollbar-width: none;
  scroll-snap-type: y mandatory;
  position: relative;

  .image-container {
    width: 100%;
    height: 100%;
    position: relative;
    scroll-snap-align: start;
  }

  .slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .credit {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>