<template>
  <div class="carousel-container">
    <div class="carousel" ref="carousel">
      <div
        class="image-container"
        v-for="(object, idx) in project.carousel"
        :key="idx"
      >
        <img
          :src="object.image"
          class="carousel-image"
          alt="project-image"
          loading="lazy"
        />
        <p class="credit" v-show="object.credit">@{{ object.credit }}</p>
      </div>
    </div>
    <div class="selector" ref="selector" v-show="numImages > 1">
      <div
        class="selector-btn"
        v-for="index in numImages"
        :key="index"
        :class="{ active: index === 1 }"
        @click="scroll(index)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['project', 'numImages'],

  methods: {
    scroll(num) {
      // 1. Remove active form from all btns
      const btns = [...this.$refs.selector.children];
      btns.forEach((el) => {
        el.classList.remove('active');
      });

      // // 2. Calculate scroll distance according to the btn clicked
      --num;
      const scroll = num * 100;

      // // 3. Add active form to current slide btn and navigate to selected project
      this.$refs.carousel.style.transform = `translateY(-${scroll}vh)`;
      btns[num].classList.add('active');
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-container {
  width: 100%;
  height: 100vh;
  margin-bottom: 8.3rem;
  overflow-y: hidden;
  scrollbar-width: none;
  position: relative;

  .carousel {
    width: 100%;
    height: 500%;
    transition: all 0.5s ease;

    .image-container {
      width: 100%;
      height: 20%;
      position: relative;
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      margin: 0;
      padding: 0;
    }

    .credit {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
</style>